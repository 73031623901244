import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import { t } from "i18next";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#550200",
    },
    secondary: {
      main: green[500],
    },
  },
});

export default function JitsGroups(props) {
  const navigate = useNavigate();

  const [packages, setPackages] = useState([]);
  const { t, i18n } = useTranslation();


  useEffect(() => {
      axios.get('https://staffportal.ramatravel.net/api/packages')
          .then(response => {
              setPackages(response.data);
          })
          .catch(error => {
              console.error('There was an error fetching the packages!', error);
          });
  }, []);


  return (
    <div className="py-3">
      <ThemeProvider theme={defaultTheme}>
      <div className="text-center text-5xl self-start pt-10 my-8">
        {t("groups").substring(0,1).toUpperCase()+t('groups').substring(1)}
      </div>
      {packages.map((p,i) => (
        <Link key={i} to="/packageDetails" state={{ p: p }}>
          <div
            // margin={1}
            className=" overflow-hidden bg-cover bg-center h-48 w-full md:h-96 md:w-1/3 border-white border-4 inline-block hover:space-x-10"
            style={{
              backgroundImage: "url(" + p.image + ")",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div
              style={{ textShadow: "2px 2px 8px #000" }}
              className=" self-center md:pt-40 text-white drop-shadow-xl hover:text-2xl hover:mb-0 mb-24 duration-500 text-4xl p-4 h-3/4 w-full text-center"
            >
              {i18n.language=='en'&& p.name_en}
              {i18n.language=='ar'&& p.name_ar}
              {i18n.language=='ckb'&& p.name_ku}              
            </div>
            <div className=" text-white  bg-opacity-30 bg-black w-full p-2 md:p-6 text-lg h-1/4 text-center align-middle">
              {i18n.language=='en'&& p.details_en}
              {i18n.language=='ar'&& p.details_ar}
              {i18n.language=='ckb'&& p.details_ku}  
            </div>
          </div>
        </Link>
      ))}
    </ThemeProvider>
    </div>
  );
}
