import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const JitsCopyright=(props)=> {
  const {t}=useTranslation();
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {t('copyright') + " © "}
        <Link color="inherit" href="https://links.4mbsoft.com/4mbsoft">
          4MB Soft
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }