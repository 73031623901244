import { CircularProgress, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

export const CongratsScreen = ({ paymentId, ticketPayment }) => {
  const { t } = useTranslation();

  const [paymentReceived, setPaymentReceived] = useState(false);
  const [checkingPayment, setCheckingPayment] = useState(true);
  const [error, setError] = useState(false);
  const [rechecking, setRechecking] = useState(true);
  const [timer, setTimer] = useState(0);

  const checkPayment = async () => {
    try {
      setError(false);
      setCheckingPayment(true);

      const token = localStorage.getItem("token");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      if(!paymentReceived) {

        const { data } = await axios.post(
          "https://staffportal.ramatravel.net/api/fib/payment/check",
          { paymentId: paymentId },
          config
        );
        if (data["status"] == "PAID") {
          setRechecking(false);
          await ticketPayment();
          setPaymentReceived(true);
        } else setPaymentReceived(false);
        setCheckingPayment(false);
      }
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {

    let retries = 3;

    if (!rechecking) return;

    const interval = setInterval( async () => {   
      
      await checkPayment();

        if(paymentReceived || retries === 0) {
          clearInterval(interval);
          setRechecking(false);
          return;
        }

        retries--;

      setTimer(prevTimer => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          return 3;
        }
      });
    }, 3000);

    return () => clearInterval(interval); 
  }, [rechecking, paymentReceived]);

  return (
    <div className="w-full h-auto self-center my-10">
      {checkingPayment ? (
        <div>
          <CircularProgress sx={{ color: "#550200" }} />
          <div className="text-[#5e1f16] text-3xl py-3">
            {t("checkingPayment")}
          </div>
        </div>
      ) : error ? (
        <center>
          <img src="/gifs/error.gif" width={200} height={200} />
          <div className="text-[#5e1f16] text-3xl py-3">
            {t("anErrorOccurred")}
          </div>
          {rechecking && (
            <div className="text-[#5e1f16] text-3xl py-3">
              {t("checkingPayment")}
            </div>
          )}
        </center>
      ) : paymentReceived ? (
        <center>
          <img src="/gifs/confetti.gif" width={200} height={200} />
          <div className="text-[#5e1f16] text-3xl py-3">
            {t("paymentReceived")}
          </div>
        </center>
      ) : (
        <center>
          <img src="/gifs/error.gif" width={200} height={200} />
          <div className="text-[#5e1f16] text-3xl py-3">
            {t("paymentNotReceivedYet")}
          </div>
          <Button variant="text" onClick={ () => checkPayment}>
            {t("checkAgain")}
          </Button>
        </center>
      )}
    </div>
  );
};
