import { List, Person } from '@mui/icons-material';
import { Box, Button, Container, Divider, Drawer, Tooltip, styled, tooltipClasses } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { JitsLanguageDropDown } from './JitsLanguageDropDown';
import { Link } from 'react-router-dom';
import { useAccountStore } from '../../stores/accountStore';


export const JitsDrawer = () => {
    const user =  useAccountStore.getState().user;
        
    const { t, i18n } = useTranslation();
    const [drawerOpen,setDrawerOpen]=useState(false);
    
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#f5f5f9',
          color: '#bf9549',
          maxWidth: 400,
          padding:10,
          margin:0,
          fontSize: theme.typography.pxToRem(12),
          border: '2px solid #bf9549',
        },
      }));

  return (
    <React.Fragment key={'start'}>
                <Button className='nav-drawer-btn' sx={{color:"white",borderColor:"yellow",}} variant="text" onClick={()=>setDrawerOpen(true)}><List/></Button>
                  <Drawer
                  anchor={i18n.dir()=='ltr'?'left':'right'}
                  open={drawerOpen}
                  onClose={()=>setDrawerOpen(false)}
                ><Container sx={{width:'300px',color:"white",background:"#5e1f16",height:"100%"}}>
                    <div dir={i18n.dir()}>
                    
             
              
                    <div className='my-5 text-center' >
                        <Link to={user.token!=""?"/profile":"/login"} className='text-center'>
                                {user.token!=""?
                                    user.avatar=="https://staffportal.ramatravel.net/storage/appusers"? <div className='flex flex-col justify-center items-center'>
                                        <div className=' rounded-full shadow-slate-50 shadow-sm' src={`${user.avatar}`} alt="" ><Person  className='w-24 h-24' sx={{width:100,height:100}}/></div>
                                        <div className='mt-3'>{t('editProfile')}</div>
                                    </div>                                    :
                                    <div className='flex flex-col justify-center items-center'>
                                        <img className='w-24 h-24 rounded-full shadow-slate-50 shadow-sm' src={`${user.avatar}`} alt="" />
                                        <div className='mt-3'>{t('editProfile')}</div>
                                    </div> :
                                    <span className='border border-[#6a201e] rounded-md py-2 px-3 text-lg mt-10'>{t('Login')}</span>
                            }
                        </Link>
                    </div>
                    {/* <div>
                            C15|غائم جزئی مع احتمال زخات امطار
                        </div> */}
                    <Divider sx={{margin:"10px"}}/>
                    <Box sx={{height:"30px"}}/>

                            <div>
                                <span className="item-nav-transition"></span>
                                <h3 className='hover:animate-bounce text-white text-xl font-medium'>
                                    <Link to={"/groups"}> <span className='text-[#bf9549]'>{t('view')}</span> {t('groups')}</Link>
                                </h3>
                            </div>
                            <Box sx={{height:"30px"}}/>

                            <div>
                                <span className="item-nav-transition"></span>
                                <h3 className='hover:animate-bounce text-white text-xl'>
                                    <Link to={'/guides'}> <span className='text-[#bf9549]'>{t('read')}</span> {t('guides')}</Link>
                                </h3>
                            </div>
                            <Box sx={{height:"30px"}}/>
                            <div>
                                <span className="item-nav-transition"></span>
                                <h3 className='hover:animate-bounce text-white text-xl'>
                                    <Link to={'/about'}>     <span className='text-[#bf9549]'>{t('about')}</span> {t('rama')}</Link>
                                </h3>
                            </div>
                            <Box sx={{height:"30px"}}/>

                            <div>
                            <Container sx={{height:"100%"}}>
                            <Divider sx={{margin:"10px"}}/>       
 
                            {/* <div className='flex items-center px-1 h-8'>
                                <i className="fa-solid fa-earth-asia"></i>
                                <select className='head-select-lang bg-transparent px-2' onChange={ (e) => changeLanguageHandler(e.target.value) } >
                                    <option value="en">English</option>
                                    <option value="ar">عربي</option>
                                    <option value="ckb">کوردی</option>
                                </select>
                            </div> */}
                            <JitsLanguageDropDown/>

                            </Container>
                            </div>
                            <Box sx={{height:"30px"}}/>


                
                    </div>
                </Container></Drawer>
                    </React.Fragment>
  )
}
