import { create } from "zustand";
import axios from "axios";
import { Await } from "react-router-dom";

export const useAccountStore = create((set) => ({
  user: {
    token: "",
    avatar: "",
    username: "",
    mobile: "",
    email: "",
    dob: null,
    gender: null,
    havePassword: null,
  },
  // loggedIn:((state)=>!state.user.token.isEmpty),
  update: async (username, email, phone, dob, gender) => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      console.error('Please enter a valid email address.');
      return false;
    }

    set((state) => ({
      user: {
        avatar: state.user.avatar,
        username: username,
        mobile: phone,
        email: email,
        dob: dob,
        gender: gender,
      },
    }));

    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const {data} = await axios.post( "https://staffportal.ramatravel.net/api/editUser",
        {
          username: username,
          mobile: phone,
          email: email,
          dob: dob,
          gender: gender,
        },
        config
      )
      .catch((error) => {
        return false;
      });

      return data?.status;
  },

  updateAvatar: (avatar) => {
    set((state) => ({
      user: {
        avatar: avatar,
        username: state.user.username,
        mobile: state.user.phone,
        email: state.user.email,
        dob: state.user.dob,
        gender: state.user.gender,
      },
    }));
  },
  login: async (email, passowrd) => {
    await axios
      .post("https://staffportal.ramatravel.net/api/login", {
        username: email,
        password: passowrd,
      })
      .then((response) => {
        const data = response.data;
        set({
          user: {
            token: data["token"],
            avatar: data["data"]["avatar"],
            username: data["data"]["username"],
            mobile: data["data"]["mobile"],
            email: data["data"]["email"],
            dob: new Date(data["data"]["dob"]).toLocaleDateString('en-US'),
            gender: data["data"]["gender"],
            havePassword: data["data"]["havePassword"],
          },
        });
        localStorage.setItem("token", data["token"]);
      })
      .catch((error) => { });
  },
  logout: () => {
    localStorage.removeItem("token");
    set({
      user: {
        token: "",
      },
    });
  },
  signup: async (data) => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(data["email"])) {
      console.error('Please enter a valid email address.');
      return false;
    }
    
    var errorMsg="";
    await axios
      .post("https://staffportal.ramatravel.net/api/signup", data)
      .then((response) => {
        const data = response.data;
        set({
          user: {
            token: data["token"],
            avatar: data["avatar"],
            username: data["username"],
            mobile: data["mobile"],
            email: data["email"],
            dob: new Date(data["dob"]).toLocaleDateString('en-US'),
            gender: data["gender"],
            havePassword: data["havePassword"],
          },
        });
        localStorage.setItem("token", data["token"]);
      })
      .catch((error) => {
        for (const key in error.response.data.errors) {
          if (Array.isArray(error.response.data.errors[key])) {
            const errorMessage = error.response.data.errors[key][0];
            if(errorMsg!="") errorMsg+="\n";
            errorMsg+=errorMessage;
          }
        }
       });
      return errorMsg;
  },
  verify: async () => {
    try{const token = localStorage.getItem("token");

    if(!token) {
      return false;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const {data} = await axios.post("https://staffportal.ramatravel.net/api/userverify", null, config).catch((error) => { return false; });
    if(data.status != 'true') {
      localStorage.removeItem("token");
      return false;
    }

    set({
      user: {
        token: data["token"],
        avatar: data["data"]["avatar"],
        username: data["data"]["username"],
        mobile: data["data"]["mobile"],
        email: data["data"]["email"],
        dob: new Date(data["data"]["dob"]).toLocaleDateString('en-US'),
        gender: data["data"]["gender"],
        havePassword: data["data"]["havePassword"],
      },
    });
    
    return true;
  }
  catch(_){return false;}
  },
  changePassword: async (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios
      .post(
        "https://staffportal.ramatravel.net/api/changePassword",
        data,
        config
      )
      .catch((error) => { });
    return res["status"];
  },
}));
