import React, { Component } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';

class DestinationExplorer extends Component {

    state = {
        data: [],
        load: false,
        showAll: false,
    };

    async componentDidMount(){
        const {data} = await axios.get("https://staffportal.ramatravel.net/api/destinationexplorer");
        this.setState({ data: data }, ()=>{
            this.setState({load: true});
        });
    }


  render() {
    const{t,i18n}=this.props;
    return (
        <div className='py-12 px-5 container m-auto'>
            <h2 className='text-2xl text-[#550200] font-medium  pr-16 mb-8'>{t('popularInternalAndNationalTrips')}</h2>

            <div className='flex flex-wrap justify-center'>

            {
                this.state.load? this.state.data.map((item, index) => 
                    {
                        return ( (this.state.showAll? true: index < 12 ) && <div  key={item['id']} className='bg-slate-50 w-80 flex justify-between items-center py-2 px-3 box-content'>
                            <div>
                                <h3 className='text-lg text-gray-900'>
                                        {i18n.language=='en'&& item['title']}
                                      {i18n.language=='ar'&& item['title_ar']}
                                      {i18n.language=='ckb'&& item['title_ku']} 
                                </h3>
                                <p className='text-base text-gray-600'>
                                    {t('ticket')} 
                                     {i18n.language=='en'&& item['vehicleTypeName']}
                                     {i18n.language=='ar'&& item['vehicleTypeName_ar']}
                                     {i18n.language=='ckb'&& item['vehicleTypeName_ku']}
                                </p>
                            </div>
                            <div className='font-bold text-gray-900'> {item['price']} IQD</div>
                            <div>
                                <i className={"fa-solid fa-angles-"+(i18n.dir()=='ltr'?'right':'left')+" text-2xl text-[#550200] cursor-pointer"} onClick={() =>{ 
                                    this.props.handleSetDestinationExplorer(item);}}></i>
                            </div>
                        </div>  )
                }
                            ) 
                : <div><center><CircularProgress color="error" /></center></div>
            }

            </div>
                <Link to={"/destinationExplorer"}> 
                    <p  role='button' 
                        className='text-lg text-[#550200] text-center mt-5'
                        > { t('seeMoreBuses') } </p>
                </Link>

                
        </div>
    )
  }
}

export default withTranslation()(DestinationExplorer);
