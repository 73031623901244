import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import { t } from "i18next";
import { CircularProgress, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState,useEffect } from "react";
import axios from 'axios';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#550200",
    },
    secondary: {
      main: green[500],
    },
  },
});

export default function PasswordResetPage() {

  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");

  const [checking,setChecking]=useState(true);
  const [validToken,setValidToken]=useState(true);
  const [loading,setLoading]=useState(true);
  const [success,setSuccess]=useState(false);
  // const [fail,setFail]=useState(false);
  const [error,setError]=useState(false);

  let params = useParams();

  
  const checkToken= ()=>{
    setChecking(true);
    setLoading(true);
    const data = {
      token: params.token,
    };
    // signup(data);
    axios.post(
      "https://staffportal.ramatravel.net/api/checkForgetPassTokenValidity",
      data
    ).then((res)=>{setValidToken(res['data']['valid']);})
    .catch((error) => { setError(true) });   
    setLoading(false);
    setChecking(false);
  }

  useEffect(() => {
    checkToken();
    },[]);


    
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
        password.length<8||
        password === "" ||
        cpassword === "" ||
        password !== cpassword
    )
    return;
    setLoading(true);
    const data = {
      password: password,
      token: params.token,
   
    };
     await axios.post(
      "https://staffportal.ramatravel.net/api/resetPassword",
      data
    ).then((res)=>{ 
      if(res['data']['status'])
        setSuccess(true);
      else
        setError(true);
      })
    .catch((error) => { setError(true) });  

    setLoading(false);
    // navigate("/");
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <div dir={useTranslation().i18n.dir()}>
        <Stack
        className=" h-96"
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
     
          {checking && <div className="text-[#5e1f16] text-3xl pt-10">
            {t("checkingToken")}
          </div> }
          {loading ? <center className="pt-10"><CircularProgress/></center>:
          error ? <center className="pt-10">
          <img src="/gifs/error.gif" alt="" width={200} height={200} />
          <div className="text-[#5e1f16] text-3xl py-3">
            {t("anErrorOccurred")}
          </div>
          </center>:
          success?
          <div className="pt-10">
            <center>
              <img src="/gifs/confetti.gif" alt="" width={200} height={200} />
              <div className="text-[#5e1f16] text-3xl py-3">
                {t("passwordSuccessfullyReset")}
              </div>
            </center>
          </div>
          :
            <Container component="main" maxWidth="xs" className=" min-h-max">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 6,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                {t("changePassword")}
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label={t("password")}
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      color={`${(password.length > 0 && password.length < 8)? 'warning' : (password.length >= 8)? 'success': ''}`}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="cpassword"
                      label={t("confirmPassword")}
                      type="password"
                      id="cpassword"
                      autoComplete="new-password"
                      color={`${(cpassword.length > 0 && cpassword !== password )? 'warning' : (cpassword === password)? 'success': ''}`}
                      onChange={(event) => setCPassword(event.target.value)}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {t("change")}
                </Button>
              </Box>
            </Box>
          </Container>}
        </Stack>
      </div>
    </ThemeProvider>
  );
}
