import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import React from "react";
import { ComingSoon } from "../componets/ComingSoon.tsx";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#550200",
    },
    secondary: {
      main: green[500],
    },
  },
});

export default function JitsGuides(props) {

  const [guides, setGuides] = useState([{}]);

  const search = () => {
    setGuides([
      { id: 1, name: "abc", details: "aodnfeunfj owinfndi ", image: "assds" },
      { id: 1, name: "abc", details: "aodnfeunfj owinfndi ", image: "assds" },
      { id: 1, name: "abc", details: "aodnfeunfj owinfndi ", image: "assds" },
    ]);
  };

  return (
    <div className="py-6">
          <ComingSoon />
    </div>
  );
  return (
    <ThemeProvider theme={defaultTheme}>
      <div className="inline-block w-1/4">
        <input type="text" />
        <br />
        <input type="button" value={"Search"} onClick={() => search()} />
      </div>
      <div className=" inline-block p-4 md:w-full w-3/4">
        <div className="text-xl ">title</div>
        <div>
          detailsdetails details detailsdetails detailsdetails detailsdetails
          detailsdetails detailsdetails detailsdetails detailsdetails
          detailsdetails detailsdetails detailsdetails detailsdetails
          detailsdetails detailsdetails detailsdetails detailsdetails
          detailsdetails detailsdetails details
        </div>
      </div>
      <hr className="px-2" />
      {guides.length !== 0 &&
        guides.map((g) => (
          <div>
            {/* <div>{g.name}</div>
            <div>{g.details}</div> */}
          </div>
        ))}
    </ThemeProvider>
  );
}
