import React, { Component } from 'react'
import { Route, Routes, Navigate } from "react-router-dom"
import axios from 'axios';
import Header from './layouts/Header'
import Footer from './layouts/Footer'
import Ticket from './componets/Ticket'
import Payment from './componets/Payment'
import Home from './pages/Home'
import TicketSearch from './componets/TicketSearch'
import JitsSplashScreen from './pages/JitsSplashScreen';
import JitsSignup from './pages/JitsSignup.tsx';
import { JitsLogin } from './pages/JitsLogin.tsx';
import { withTranslation } from 'react-i18next';
import { useAccountStore } from '../stores/accountStore.jsx';
import NotFound from './pages/NotFound.jsx';
import AllDestinationExplorer from './pages/AllDestinationExplorer.jsx';
import i18n from '../i18n/config.js';

import UserTickets from './componets/UserTickets.jsx';
import PasswordResetPage from './pages/JitsPasswordResetPage.jsx';
import PasswordForgetPage from './pages/JitsPasswordForgetPage.jsx';
import JitsProfile from './pages/JitsProfile';
import JitsAboutRama from './pages/JitsAboutRama.tsx';
import JitsGroups from './pages/JitsGroups.tsx';
import JitsGuides from './pages/JitsGuides.tsx';
import JitsPackageDetails from './pages/JitsPackageDetails.tsx'
import GoogleCallback from './componets/GoogleCallback.jsx';

class Main extends Component {
  
  account = useAccountStore.getState().user;
  constructor(props) {
    super(props);
    this.ticketSearch = this.ticketSearch.bind(this);
    this.loadingFinished=this.loadingFinished.bind(this)
  } 

  

  state = {
    vehicleType: 'mini-bus',
    destinationValue: null,
    fromValue: 'Duhok',
    toValue:'None',
    datePickerIsOpen: false,
    datePickerStartDate: new Date(),
    passengerNumber: 1,
    tickets: null,
    selectTicket: null,
    showTickets: false,
    showPayment: false,
    showHome: false,
    loading:true,
    splashScreenTimeout:false,
    userVerified:false,
    searching:false,
    showSignup:false,
    noCityPicked:false,
    noTicketsFound:false,
    ticketsCities:[],
   };


   async componentDidMount(){
    this.props.i18n.changeLanguage(i18n.language.split('-')[0]);

    const {data} = await axios.get("https://staffportal.ramatravel.net/api/cities");

    data.forEach(function(item) {
        item.icon = '/images/location.png';
    });

    this.setState({ ticketsCities: data });
    
    const verifyUser = await useAccountStore.getState().verify();
    this.setState({userVerified: true});
    this.setState({loading: !(this.state.splashScreenTimeout && this.state.userVerified)});

  }

  setVehicleType = (value) => {
    // let n=2;
    // if(this.props.i18n.dir()=='rtl')
    // switch(value){
    //   case "car":
    //     n=2;
    //     break;
    //   case "bus":
    //     n=1;
    //     break;
    //   case "mini-bus":
    //     n=0;
    //     break;
    // }
    // else switch(value){
    //   case "car":
    //     n=0;
    //     break;
    //   case "bus":
    //     n=1;
    //     break;
    //   case "mini-bus":
    //     n=2;
    //     break;
    // }
    // const bgOffset = 33.33 * n;
    // document.querySelector(".jcontainer").style.setProperty("--bg-offset", `${bgOffset}%`);
    this.setState({vehicleType: value});
  }

  setDestinationValue = (value,name) => {
    this.setState({destinationValue: value,noCityPicked:false,toValue:name});
  }

  setDatePickerValue = (value) => {
    this.setState({datePickerStartDate: value.toDate() });
  }
  setFromValue = (value) => {
    this.setState({fromValue: value });
  }

  handlePassengerNumberChange = (value) => {
    this.setState({ passengerNumber: value });
  }

  handleSetDestinationExplorer = (value) => {
    this.setState({vehicleType: value['vehicleType'], destinationValue: value['arrivalCityId']}, () => {
      // this.ticketSearch();
      this.setVehicleType(value['vehicleType']);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  }

  loadingFinished=()=>{
    this.setState({splashScreenTimeout:true});
    this.setState({loading: !(this.state.splashScreenTimeout && this.state.userVerified)});
  }

  async ticketSearch() {

    if(this.state.showTickets || this.state.showPayment){
      this.setState({loading: true});
      this.setState({ showTickets: false, showPayment: false, showHome: true, vehicleType: 'mini-bus',destinationValue: null, passengerNumber: 1 });

      setTimeout(() => {
        this.setState({ loading: false });
      }, 1500)
      return;
    }

    this.setState({searching:true,noTicketsFound:false,});

    const {data} = await axios.post('https://staffportal.ramatravel.net/api/ticketSearch', {
      vehicleType: this.state.vehicleType,
      date: this.state.datePickerStartDate,
      arrivalCity: this.state.destinationValue,
      passengerNumber: this.state.passengerNumber,
    });

    this.setState({ tickets: data }, ()=>{
      if (data && data.length > 0) {
        sessionStorage.setItem('tickets',  JSON.stringify(data));
        this.setState({ showTickets: true, });      
      }
      else 
      // if(data.length==0)
      {
        this.setState({noTicketsFound:true});
      }
      this.setState({ showPayment: false, });
    });

    this.setState({searching:false});

  }

  selectTicket = (value) => {
    
    this.setState({selectTicket: value, showPayment: true, showTickets: false,});
    sessionStorage.setItem('selectTicket',  JSON.stringify(value));

    if(useAccountStore.getState().user.token == ""){
      sessionStorage.setItem('redirectToPayment',  true);
    }

  }

  // showDestinationExplorerPage = (value) => {
  //   this.setState({showAllDestinationExplorer: value});
  // }

  showMain = () => {
    this.setState({ showPayment: false, 
                    showTickets: false,
                    showHome: true,
                    vehicleType: 'mini-bus',
                    destinationValue: null, 
                    passengerNumber: 1
                  });
  }

  showOtherPage = () => {
    this.setState({ showPayment: false, 
                    showTickets: false, 
                    vehicleType: 'mini-bus',
                    destinationValue: null,
                    showHome: false,
                    passengerNumber: 1
                  });
  }

  setNoTicketsFound = (val) => {
    this.setState({noTicketsFound: val});
  }

  render() {
    const{t,i18n}=this.props
    
    if(this.state.loading) 
      return <JitsSplashScreen loading = {this.state.loading} onLoadingFinished={this.loadingFinished}/>

    const pathname = window.location.pathname;

    const showTicketSearch = pathname === '/' || pathname === '/tickets' || pathname === '/payment';


    return (
      
      <div dir={i18n.dir()} className='main-wrapper'>

          <Header
                  showTickets = {this.state.showTickets || this.state.showPayment} 
                  showMain = {this.showMain}
                  showOtherPage = {this.showOtherPage} />

          { 
            showTicketSearch? 
              <TicketSearch 
                            vehicleType = {this.state.vehicleType}
                            destinationValue = {this.state.destinationValue}
                            datePickerStartDate = {this.state.datePickerStartDate}
                            passengerNumber = {this.state.passengerNumber}
                            tickets = {this.state.tickets}
                            selectTicket = {this.state.selectTicket}
                            setShowTickets = {this.setShowTickets}
                            showTickets = { (this.state.showTickets || this.state.showPayment) }
                            noTicketsFound = {this.state.noTicketsFound}
                            setNoTicketsFound = {this.setNoTicketsFound}
                            setVehicleType = {this.setVehicleType}
                            setDestinationValue = {this.setDestinationValue}
                            setDatePickerValue = {this.setDatePickerValue}
                            handlePassengerNumberChange = {this.handlePassengerNumberChange} 
                            ticketSearch = {this.ticketSearch} 
                            noCityPicked={this.state.noCityPicked}
                            searching={this.state.searching}
                            setFromValue = {this.setFromValue}
                            fromValue={this.state.fromValue}
                            ticketsCities = {this.state.ticketsCities}
                            /> : ''
          }

                  {
                    this.state.showHome && <Navigate to='/'/>
                  }     
                  {
                    this.state.showTickets && <Navigate to='/tickets'/>
                  }
                  {
                    this.state.showPayment && ( useAccountStore.getState().user.token!="" ? <Navigate to='/payment'/>:<Navigate to='/login'/>)
                  }
                  {
                    this.state.account==0 && <Navigate to='/login'/>
                  }

              <Routes>
                  <Route excact path="/" element={<Home 
                                                      showTickets = {this.state.showTickets}
                                                      handleSetDestinationExplorer = {this.handleSetDestinationExplorer}
                                                      />} />
                  
                  <Route path="tickets" element={<Ticket 
                                              tickets = {this.state.tickets}
                                              vehicleType = {this.state.vehicleType}
                                              passengerNumber = {this.state.passengerNumber}
                                              selectTicket = {this.selectTicket} />} />
                  
                  <Route path="payment" element={<Payment 
                                              date = {this.state.datePickerStartDate}
                                              showPayment = {this.state.showPayment}
                                              selectTicket = {this.state.selectTicket} 
                                              passengerNumber = {this.state.passengerNumber}
                                              vehicleType = {this.state.vehicleType}
                                              destinationValue = {this.state.destinationValue} 
                                              fromValue={this.state.fromValue}
                                              toValue={this.state.toValue} />} />
                                              
                  <Route path="login" element={<JitsLogin/>} />
                  <Route path="signup" element={<JitsSignup />} />
                  <Route path='destinationExplorer'  element={<AllDestinationExplorer handleSetDestinationExplorer={this.handleSetDestinationExplorer}/>}/>

                  <Route path='profile' element={<JitsProfile/>}/>
                  <Route path='about' element={<JitsAboutRama/>}/>
                  <Route path='groups' element={<JitsGroups/>}/>
                  <Route path='guides' element={<JitsGuides/>}/>
                  <Route path='packageDetails' element={<JitsPackageDetails/>}/>
                  <Route path="auth/google" element={<GoogleCallback />}></Route>
                  <Route path='userTickets' element={<UserTickets />}/>
                  <Route path='forgetPassword' element={<PasswordForgetPage />}/>
                  <Route path='forgetPassword/:token' element={<PasswordResetPage />}/>
                
                  <Route path="*" element={<NotFound />} />

                </Routes>


          {/* <Payment/> */}


          <Footer/>


        </div>
    )
  }
}

export default withTranslation()(Main)
