import Aos from "aos";
import React from "react";
import "aos/dist/aos.css";
import { Animate } from "react-simple-animate";
import { useTranslation } from "react-i18next";

export const ComingSoon = () => {
  const { t } = useTranslation();
  Aos.init();
  return (
    <div className="w-full h-auto self-center">
      <center>
        <Animate
          play={true}
          start={{ opacity: 1, filter: "blur(10px)" }}
          end={{ opacity: 1, filter: "blur(0px)" }}
          duration={1}
        >
          <div className="text-[#5e1f16] text-3xl">{t("comingSoon")}</div>
        </Animate>
        {/* <div className="w-[200px]">
          <LinearProgress color="warning" />
        </div> */}
        {/* <hr style={{width:w,backgroundColor:"yellow"}}/> */}
      </center>
    </div>
  );
};
