import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";

export const JitsLanguageDropDown = ({color="white"}) => {
  const [t, i18n] = useTranslation();
  const changeLanguageHandler = (lang) => {

    i18n.changeLanguage(lang);
      if(lang=="en")
        document.documentElement.dir = "ltr";
      else
        document.documentElement.dir="rtl";
  };

  return (
    // <ThemeProvider theme={{ primary: "red" }}>
      <FormControl sx={{ m: 1, minWidth: 150 }} className='langSelect'>
        
        <Select        
          value={i18n.language}
          //   onChange={changeLanguageHandler}
          inputProps={{ "aria-label": "Without label" }}
          color="error"
          //   style={{color:"red"}}
          sx={{ color: color }}
          className="flex flex-row !justify-between"
        >
          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
          {
            <MenuItem className="flex flex-row !justify-between" value="en" onClick={() => changeLanguageHandler("en")}>
              <span>English</span> 
              <img src="/images/icons/united-kingdom-flag.png" alt="" />
            </MenuItem>
          }
          {
            <MenuItem className="flex flex-row !justify-between" value="ar" onClick={() => changeLanguageHandler("ar")}>
              <span>عربي</span>
              <img src="/images/icons/iraq-flag.png" alt="" />
            </MenuItem>
          }
          {
            <MenuItem className="flex flex-row !justify-between" value="ckb" onClick={() => changeLanguageHandler("ckb")}>
              <span>کوردی</span>
              <img src="/images/icons/kurdistan-flag.png" alt="" />
            </MenuItem>
          }
        </Select>
      </FormControl>
    // </ThemeProvider>
  );
};
