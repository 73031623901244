import React, { Component } from 'react'
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { withTranslation } from 'react-i18next';

class UserTickets extends Component {

    state = {
        loading: true,
        redirect: false,
        tickets: [],
    }

    componentDidMount() {

        const token = localStorage.getItem('token');

        // if (token != '') {
        //     this.setState({redirect: true});
        //     return;
        // }
        const config = { headers: { "Authorization": `Bearer ${token}` }, }; 

        axios.post('https://staffportal.ramatravel.net/api/tickets', null, config)
                .then(response => {    
                    const data = response.data;
                    this.setState({tickets: data.data}, () => {
                        this.setState({loading: false});
                    });
                })
                .catch(error => { console.error('Error:'+ error) });

    }

  render() {
    
     const {t}=this.props

    return (
        <>
            
            {/* {
                this.state.redirect && <Navigate to='/'/>
            } */}

            {
                this.state.loading ? 
                    <div><CircularProgress sx={{color:'#6a201e'}}/></div> :
                    <div>
                        <table className='user-tickes-table w-[750px] md:w-full'>
                            <thead className='bg-[#6a201e] text-white'>
                                <tr>
                                    <td>{t('ref')}</td>
                                    <td>{t('ticketType')}</td>
                                    <td>{t('passengerCount')}</td>
                                    <td>{t('amount')}</td>
                                    <td>{t('date')}</td>
                                    <td>{t('status')}</td>
                                    <td>{t('createdAt')}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.tickets.map( (item, index) => {
                                        return <tr key={index}>
                                                <td>{item['ref']}</td>
                                                <td>{item['ticket_type']}</td>
                                                <td>{item['passenger_numbers']}</td>
                                                <td>{item['amount']}</td>
                                                <td>{item['date']}</td>
                                                <td>{item['payment_statuts']}</td>
                                                <td>{item['created_at']}</td>
                                            </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
            }

        </>
        
    )
  }
}

export default withTranslation()(UserTickets)