import { useTranslation, withTranslation } from 'react-i18next';
import './App.css';
import Main from './views/Main';
import { BrowserRouter } from "react-router-dom"

import NoInternetConnection from './views/componets/NoInternetConnection';


function App() {
  const { t, i18n } = useTranslation();
  document.body.style.dir = i18n.dir();

  return (
    <NoInternetConnection>
      <BrowserRouter>
        <Main/>
      </BrowserRouter>
    </NoInternetConnection>
  );
}

export default withTranslation()(App);
