import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import { t } from "i18next";
import { CircularProgress, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import axios from 'axios';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#550200",
    },
    secondary: {
      main: green[500],
    },
  },
});

export default function PasswordForgetPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [loading,setLoading]=useState(false);
  const [success,setSuccess]=useState(false);
  const [fail,setFail]=useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      email === "" 
    )
    return;
    setLoading(true);
    const data = {
      email: email,
    };
    const res = await axios.post(
      "https://staffportal.ramatravel.net/api/forgetPassword",
      data
    )
    .catch((error) => { });

    if(res['data']['status'])
      setSuccess(true);
    else
      setFail(true);
    
    setLoading(false);
    // navigate("/");
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <div dir={useTranslation().i18n.dir()}>
        <Stack
        className=" h-96"
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
          {loading?<center className="pt-10"><CircularProgress/></center>:
          success?
          <Stack  direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
          className=" pt-14 text-center content-center">
            <img src="/gifs/email-file.gif" alt="" width={200} height={200} />
            <div className="text-[#5e1f16] text-3xl pt-4">
            {t("resetEmailHasBeenSent")} 
          </div>
          <div className="text-[#5e1f16] text-xl pt-4 pb-10">
            {t('youMayCloseThisWindow')}
          </div></Stack>
          :fail? 
          <Stack  direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
          className=" pt-14 text-center">
            <img src="/gifs/error.gif" alt="" width={200} height={200} />
          <div className="text-[#5e1f16] text-3xl pt-4">
            {t("anErrorOccurred")}
            <Button
                  type="button"
                  fullWidth
                  onClick={()=>{
                   navigate('/forgetPassword')
                  }}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {t("tryAgain")}
                </Button>
          </div>
          </Stack>:

            <Container component="main" maxWidth="xs" className=" min-h-max">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 6,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                {t("forgotPassword")}
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      type="email"
                      label={t("email")}
                      name="email"
                      autoComplete="email"
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {t("resetPassword")}
                </Button>
              </Box>
            </Box>
          </Container>}
          
        </Stack>
      </div>
    </ThemeProvider>
  );
}
