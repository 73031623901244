import React, { Component, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import Navigation from './Navigation';

class Header extends Component {
    state = { width: window.innerWidth, height: window.innerHeight};
    
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    changeLanguageHandler = (lang) => {
        this.props.i18n.changeLanguage(lang);
          if(lang=="en")
            document.body.style.dir="ltr";
            else
            document.body.style.dir="rtl";
    };
    
    render() {
            // const name=useAccountStore().user.name;
            // const loggedIn=(name!="");
            
        const{t,i18n}=this.props;

        const pathname = window.location.pathname;
        const showHeadBg = pathname === '/' || pathname === '/tickets' || pathname === '/payment';
    
        return (
            <header dir={i18n.dir()} className={`relative`} style={ showHeadBg ? {'backgroundImage': 'url("/images/bg-header.jpg")', 'backgroundSize': 'cover', 'backgroundPosition': 'center'}: {}}>
                <div className="bg-header-wave w-full absolute z-0 rotate-180 lg:bg-[#6a201e]">
                    <svg className='h-full' width="100%" height="100%" version="1.1" x="0px" y="0px" viewBox="0 0 1698 135" preserveAspectRatio="none" xmlns="https://www.w3.org/2000/svg" >
                        <path opacity="0.2" fill="#5e1f16" d="M1999.5,22.2c-346-0.6-524.6-4.7-878.8,4.4c-286.6,7.4-442.3,54-608.1,51.2C307.3,74.3,202.5,5-0.5,28.1v100.4l2000-0.5V22.2z"></path>
                        <path opacity="0.2" fill="#5e1f16" d="M-0.3,46.1C251,15.3,440.9,84.7,499.6,98.4c54.7,12.8,122.5,12,186.7-5.3c154.2-41.6,315.5-70.9,475.2-67.5s324.6,22.4,484.3,19.7c133-2.3,302.8,1.7,352.8,3.7c0,21.3,0,80,0,80H-0.5L-0.3,46.1z"></path>
                        <path opacity="0.4" fill="#521411" d="M2000,41.2c-139.8-12.7-219.9-10.8-360.2-11.2c-285.5-0.8-487.5,18-736.2,51.1C647,115.4,546.7,116.4,199.2,53.6C140.3,43,59.5,45.6-0.5,52.3V130h2000L2000,41.2z"></path>
                        <path fill="#501212" d="M1634.6,50.1c-293.8,11.9-366.9,24.9-569,50c-110.2,13.7-221.2,21.5-332.3,19.6c-187-3.3-344.5-29.7-560.9-69.8c-122.2-22.6-172.8-4-172.8-4V230h1998V46C1997.5,46,1831,38.1,1634.6,50.1z"></path>
                    </svg>
                </div>
                <Navigation showMain = {this.props.showMain} showOtherPage = {this.props.showOtherPage} />
                
                {
                    showHeadBg ? <div className={`${this.props.showTickets? 'h-[16rem]': 'h-[32rem]'} transition-all`} >
                                    <div className={`${this.props.showTickets? 'bottom-[-92px] !h-[28%]': 'bottom-[-85px] lg:bottom-[-88px]'} bg-header-footer-wave w-full absolute z-0 rotate-180`}>
                                        <svg width="100%" height="100%" version="1.1" x="0px" y="0px" viewBox="0 0 1698 135" preserveAspectRatio="none" xmlns="https://www.w3.org/2000/svg" >
                                            <path opacity="0.2" fill="#5e1f16" d="M1999.5,22.2c-346-0.6-524.6-4.7-878.8,4.4c-286.6,7.4-442.3,54-608.1,51.2C307.3,74.3,202.5,5-0.5,28.1v100.4l2000-0.5V22.2z"></path>
                                            <path opacity="0.2" fill="#5e1f16" d="M-0.3,46.1C251,15.3,440.9,84.7,499.6,98.4c54.7,12.8,122.5,12,186.7-5.3c154.2-41.6,315.5-70.9,475.2-67.5s324.6,22.4,484.3,19.7c133-2.3,302.8,1.7,352.8,3.7c0,21.3,0,80,0,80H-0.5L-0.3,46.1z"></path>
                                            <path opacity="0.4" fill="#521411" d="M2000,41.2c-139.8-12.7-219.9-10.8-360.2-11.2c-285.5-0.8-487.5,18-736.2,51.1C647,115.4,546.7,116.4,199.2,53.6C140.3,43,59.5,45.6-0.5,52.3V130h2000L2000,41.2z"></path>
                                            <path fill="#501212" d="M1634.6,50.1c-293.8,11.9-366.9,24.9-569,50c-110.2,13.7-221.2,21.5-332.3,19.6c-187-3.3-344.5-29.7-560.9-69.8c-122.2-22.6-172.8-4-172.8-4V230h1998V46C1997.5,46,1831,38.1,1634.6,50.1z"></path>
                                        </svg>
                                    </div> 
                                    </div>: ''
                }
            </header>
        )
    }
}
export default withTranslation()(Header)