import React, { Component } from 'react'

export default class PaymentQrcode extends Component {

    qrCode = this.props.qrCode;

  render() {
    const {qrCode} = this.props;

    return (
      <div className='container m-auto py-28 flex flex-col justify-center items-center'>
        <p className='text-lg mb-4'>Scan to Pay</p>
        <img src={qrCode['data']} className='m-auto' alt="" />
      </div>
    )
  }
}
