import { Box, Container, Stack, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { AccountProfile } from '../componets/account-profile';
import { AccountProfileDetails } from '../componets/account-profile-details';
import { useAccountStore } from '../../stores/accountStore';
import {  useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import UserTickets from '../componets/UserTickets';
import JitsPasswordChange from '../componets/JitsPasswordChange';
import { CircularProgress } from '@mui/material';

const JitsProfile = () => {
  const {t}=useTranslation();
  const navigate=useNavigate();
  const { verify } = useAccountStore();
  const [showTickets,setShowTickets]=useState(false);
  const [showPasswordChanger,setShowPasswordChanger]=useState(false);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const verifyUser = async () => {
      const verified = await verify();
      if(!verified) 
        navigate('/login');
      setLoading(false);
    }
    verifyUser();
  }, []);

  return <div dir={useTranslation().i18n.dir()}>

    {
        loading ? <div className="w-full h-screen flex justify-center items-center "><CircularProgress sx={{color:'#501212'}}/></div> :
        <>
          <title>
            {t('account')}
          </title>
        
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 4
          }}
        >
          
          <Container maxWidth="lg">
            <Stack spacing={3}>
              <div>
                <Typography variant="h4">
                  {t('account')}
                </Typography>
              </div>
              <div>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    xs={12}
                    md={6}
                    lg={4}
                  >
                    <AccountProfile handleSetShowTickets={setShowTickets} showTickets={showTickets} setShowPasswordChanger={setShowPasswordChanger} showPasswordChanger={showPasswordChanger}/>
                  </Grid>
                  <Grid
                    xs={12}
                    md={6}
                    lg={8}
                  >
                    {showTickets? <div className="mt-16 mx-4 h-auto text-center align-top justify-center overflow-x-scroll">
            <UserTickets />
          </div>:
          showPasswordChanger?<JitsPasswordChange/>:
                    <AccountProfileDetails />}
                  </Grid>
                </Grid>
              </div>
            </Stack>
          </Container>
    
        </Box>
  
      </>
    }
    </div>
  

  }



export default JitsProfile;
