import React, { Component } from 'react'
import TravelExplorer from '../componets/TravelExplorer'
import DestinationExplorer from '../componets/DestinationExplorer'
import Feature from '../componets/Feature'

export default class Home extends Component {
  render() {

    const {showTickets} = this.props;

    return (
      !showTickets &&
      <>
        <Feature />

        <TravelExplorer     handleSetDestinationExplorer = {this.props.handleSetDestinationExplorer}/>

        <DestinationExplorer 
                            handleSetDestinationExplorer = {this.props.handleSetDestinationExplorer}
                             />
      </>
    )
  }
}
