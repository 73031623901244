import React, { Component } from 'react'
import axios from 'axios';
import PaymentQrcode from './PaymentQrcode';
import { withTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, DialogActions, DialogTitle, Stack } from '@mui/material';
import { Navigate } from "react-router-dom"

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { FromToImage } from './FromToImage.tsx';
import { CongratsScreen } from './CongratsScreen.tsx';

class Payment extends Component {

    state = {
        users: [],
        paymentStatus: false,
        qrCode: null,
        qrCodeShow: false,
        checkingCoupon:false,
        couponCode:'',
        couponData:null,
        couponActiviated:false,
        couponInvalid:false,
        totalPrice: '',
        totalAmount: '',
        showAlert: false,
        alertMsg: '',
        selectTicket: null,
        navigate: false,
        step:1,
        showDialog:false,
        appLink:null,
        paymentId:null,
        readableCode:null,
        stepping:false,
        paymentDone:false,
        loading: true,
        showLoading: false,
    }

    async componentDidMount() {

          const sessionUsers = sessionStorage.getItem('paymentUsers');

          if(!sessionUsers)
          {
            const users = Array(parseInt(this.props.passengerNumber)).fill({ firstName: '', lastName: '', idNumber: '', dob: '', phone: '' });
            this.setState({ users });
          }else
          {
            const storedUsers = JSON.parse(sessionUsers);
            const passengerNumber = parseInt(this.props.passengerNumber);

              if (storedUsers.length < passengerNumber) {
                const additionalUsers = Array(passengerNumber - storedUsers.length).fill({ firstName: '', lastName: '', idNumber: '', dob: '', phone: '' });
                const users = [...storedUsers, ...additionalUsers];
                this.setState({ users });
              } else if (storedUsers.length > passengerNumber) {
                const users = storedUsers.slice(0, passengerNumber);
                this.setState({ users });
              }else {
                const users = [...storedUsers];
                this.setState({ users });
              }

          }

          const selectTicket = sessionStorage.getItem('selectTicket');

          if(!selectTicket){
            this.setState({ navigate: true });
          }

          this.setState({ selectTicket: JSON.parse(selectTicket) }, () => {
            let total = this.state.selectTicket['price'] * this.props.passengerNumber;
            this.setState({ totalPrice: total, totalAmount: total, load: true});
          });

          setTimeout(()=> {
                this.setState({ loading: false });
            }, 500);

    }

    handleChange = (event, userId, field) => {
        const updatedUsers = [...this.state.users];
        const updatedUser = { ...updatedUsers[userId], [field]: event.target.value };
        updatedUsers[userId] = updatedUser;
        this.setState({ users: updatedUsers });

        sessionStorage.setItem('paymentUsers',  JSON.stringify(updatedUsers));
    };


    async ticketPayment() {

        const token = localStorage.getItem('token');

        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        };

        const {data} = await axios.post('https://staffportal.ramatravel.net/api/ticketPayment', {
          vehicleType: this.props.vehicleType,
          date: this.props.date,
          arrivalCity: this.props.destinationValue,
          passengerNumber: this.props.passengerNumber,
          passengers: JSON.stringify(this.state.users),
          ticket: JSON.stringify(this.props.selectTicket),
          couponCode: this.state.couponCode
        }, config);
    
        this.setState({ paymentStatus: data });

        if(data.status == true || data.stfatus == 'true'){
            this.setState({paymentDone:true});
            // this.setState({showAlert : true, alertMsg: 'alertPaymentSuccess', });
            // this.setState({qrCodeShow : true});
        }
    }
      
    async checkCoupon() {
        
        if(this.state.checkingCoupon || this.state.couponCode=='') return;


        this.setState({checkingCoupon:true,couponInvalid:false});

        const token = localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
            
        await axios.post("https://staffportal.ramatravel.net/api/couponCheck",{
                couponCode:this.state.couponCode,}, config)
            .then(response => {
                    const data=response.data;
                    if(data.status == true || data.status == 'true')
                    {
                        this.setState({couponActiviated:true, couponData:data.data });
                        let amount = data.data.discount_type == 1 ?
                                    ( this.state.totalPrice - data.data.discount) :
                                    ( this.state.totalPrice-((this.state.totalPrice * data.data.discount) / 100));
                        this.setState({ totalAmount: amount});
                    }
                    else{
                        this.setState({couponInvalid:true})
                    }
            })
            .catch(error => {
                console.error('Error:'+ error);
                this.setState({couponInvalid:true})
            });
        this.setState({checkingCoupon:false});
    }
    async deleteCoupon() {
        document.getElementById('couponInput').value='';
        this.setState({couponData:null,couponActiviated:false,couponCode:'', totalAmount: this.state.totalPrice});
    }

    handleAlertClose = () => {
        this.setState({showAlert : false, alertMsg: '' });
    }
    
    async next(){
        
        this.setState({stepping:true});
        try{let inputPass=true;
        for(let i=0;i<this.state.users.length;i++){
            let cu=this.state.users[i];
            if(cu.firstName==''||cu.lastName==''||cu.dob==''||cu.idNumber==''||cu.phone=='')
            {
                inputPass=false;
                break;
            }
            else{
                if(this.state.step==1)
                {
                    this.setState({showLoading: true});
                    const token = localStorage.getItem('token');

                    const config = {
                        headers: {
                            "Authorization": `Bearer ${token}`,
                        },
                    };
                    const {data} = await axios.post('https://staffportal.ramatravel.net/api/fib/payment/create',{"money":this.state.totalAmount,},config);
                    this.setState({ qrCode: data['qrCode'],appLink:data['personalAppLink'] ,paymentId:data['paymentId'],readableCode:data.readableCode, showLoading: false}, ()=> {
                        // this.setState({ qrCodeShow: true });
                    });
                }
            }
        }
        if(inputPass){
            this.setState({step:this.state.step+1});
        }else{
            this.setState({showDialog:true});
        }
    }catch(e){}
        this.setState({stepping:false});

    }
    previous(){
        this.setState({step:this.state.step-1});
    }

    
    render() {
        const {t,i18n}=this.props;
        // leftOrRight=i18n.dir()=='ltr'?'left':'right';

        const { passengerNumber, date} = this.props;

        if(this.state.loading)
            return <div className="w-full flex justify-center items-center bg-gray-100 pb-6 pt-20 min-h-[35vh]"><CircularProgress sx={{color:'#501212'}}/></div>

        return (
                
                this.state.qrCodeShow? <PaymentQrcode qrCode = {this.state.qrCode} />:
                (this.state.navigate || !this.props.showPayment )? <Navigate to='/'/> :
                this.state.selectTicket ?
                <div className='flex flex-wrap justify-between px-6 container mx-auto pt-20 '>
                    
                    <div className='w-full lg:w-2/3 my-6 overflow-clip grid-cols-3'>
                    {
                        this.state.showDialog &&     
                        <Dialog
                            open={this.state.showDialog}
                            onClose={()=>this.setState({showDialog:false})}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                            {t('inputError')}
                            </DialogTitle>
                            <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t('pleaseEnsureAllFieldsAreFilled')}
                            </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            <Button sx={{bgcolor:'#550200',color:'#bf9549'}} onClick={()=>this.setState({showDialog:false})}>{t('ok')}</Button>
                            </DialogActions>
                        </Dialog>
                    }
      
                        <Stack  className='block-inline'
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            >
                            {(this.state.step>=1)?<div className='h-2 w-28 rounded me-1 text-center'>
                                <div>
                                    {t('info')}
                                </div>
                                <div className={' h-2 w-28 rounded me-1 bg-[#550200] duration-300 '+(this.state.step>=1?' bg-opacity-70': ' bg-opacity-20')}></div>
                            </div>:<Box/>}
                            <div className='h-2 w-28 rounded me-1 text-center'>
                                <div>
                                    {t('payment')}
                                </div>
                                <div className={' h-2 w-28 rounded me-1 bg-[#550200] duration-300 '+(this.state.step>=2?' bg-opacity-70': ' bg-opacity-20')}></div>
                            </div>
                            <div className='h-2 w-28 rounded me-1 text-center'>
                                <div>
                                    {t('success')}
                                </div>
                                <div className={' h-2 w-28 rounded me-1  bg-[#550200] duration-300  '+(this.state.step>=3?' bg-opacity-70': ' bg-opacity-20')}></div>
                            </div>
                            
                        </Stack>
                        
                        <h2 className='text-2xl mb-6 h-11'>
                            {/* {t('payment')} */}
                        </h2>
                        <div className=' overflow-clip  w-full'>
                        <div className={'  w-full relative overflow-clip duration-500 start-0  '+( (this.state.step>1 || this.state.showLoading) &&' hidden' )}>

                            {
                                this.state.users.map((user, index) => 
                                    <div key={index} className='mb-6'>
                                        <h3 className='mb-4'>
                                            <i className="fa-solid fa-user mx-1 text-[#550200]"></i>{t('infoOfPerson')} ({index + 1})
                                        </h3>
                                        
                                        <div className='bg-gray-100 rounded flex flex-wrap justify-between text-gray-700 p-4 mx-4'>
                                            <div className='mb-3 w-full lg:w-1/2'>
                                                <label className='block mb-2 text-sm' htmlFor="">{t('firstName')}<span className='text-red-700 mx-1'>*</span></label>
                                                <input type="text" 
                                                        className='border p-1 w-[95%]'
                                                        value={user.firstName}
                                                        onChange={(e) => this.handleChange(e, index, 'firstName')} />
                                            </div>

                                            <div className='mb-3 w-full lg:w-1/2'>
                                                <label className='block mb-2 text-sm' htmlFor="">{t('secondName')}<span className='text-red-700 mx-1'>*</span></label>
                                                <input type="text" 
                                                        className='border p-1 w-[95%]'
                                                        value={user.lastName}
                                                        onChange={(e) => this.handleChange(e, index, 'lastName')} />
                                            </div>

                                            <div className='mb-3 w-full lg:w-1/2'>
                                                <label className='block mb-2 text-sm' htmlFor="">{t('passportNumber')}<span className='text-red-700 mx-1'>*</span></label>
                                                <input type="text" 
                                                        className='border p-1 w-[95%]'
                                                        value={user.idNumber}
                                                        onChange={(e) => this.handleChange(e, index, 'idNumber')} />
                                            </div>

                                            <div className='mb-3 w-full lg:w-1/2'>
                                                <label className='block mb-2 text-sm' htmlFor="">{t('dateOfBirth')}<span className='text-red-700 mx-1'>*</span></label>
                                                <input type="date" 
                                                        className='border p-1 w-[95%]'
                                                        value={user.dob}
                                                        onChange={(e) => this.handleChange(e, index, 'dob')} />
                                            </div>

                                            <div className='mb-3 w-full lg:w-1/2'>
                                                <label className='block mb-2 text-sm' htmlFor="">{t('phoneWhatsappNumber')}<span className='text-red-700 mx-1'>*</span></label>
                                                <input type="text" 
                                                            className='border p-1 w-[95%]'
                                                            value={user.phone}
                                                            onChange={(e) => this.handleChange(e, index, 'phone')} />
                                            </div>

                                            {/* <div className='mb-3 w-full lg:w-1/2'>
                                                <label className='block mb-2 text-sm' htmlFor="">{t('email')}<span className='text-red-700 mx-1'>*</span></label>
                                                <input type="text" 
                                                        className='border p-1 w-[95%]'
                                                        value={user.email}
                                                        onChange={(e) => this.handleChange(e, index, 'email')} />
                                            </div> */}
                                        </div>
                                    </div>
                                    )
                            }
                            


                            <div>
                                <div className='bg-gray-100 rounded flex flex-wrap justify-between text-gray-700 p-4 mx-4'>
                                    <div className='mb-3 w-full'>
                                        <label className='block mb-2 text-sm' htmlFor="">{t('notes')}<span className='text-red-700 mx-1'>*</span></label>
                                        <textarea className='w-full border' rows="8"></textarea>
                                    </div>

                                </div>
                            </div>

                        </div>

                        {
                            this.state.showLoading? (
                                <div className="w-full h-full flex justify-center items-center ">
                                  <CircularProgress sx={{ color: "#501212" }} />
                                </div>
                              ): ''
                        }
                        
                       {this.state.qrCode!=null&&
                       <div className={' text-center w-full relative overflow-clip h-0  duration-500'+(this.state.step==2?' h-80 start-0 ':this.state.step<2?' start-[100%]':' -start-[100%]')}>
                            <div className='container h-full py-2  justify-center items-center'>
                                <div className='text-lg mb-4'>{t('scanToPay')}</div>
                                <img src={this.state.qrCode} className='m-auto' alt="" />
                                <div><button onClick={() => {navigator.clipboard.writeText(this.state.readableCode);
                                alert(t('paymentCodeCopied'))}} className='m-auto text-xl pt-2 text-[#06989F]' alt="" >{this.state.readableCode}</button></div>
                                <a href={this.state.appLink} onClick={()=>this.next()} className='m-auto text-xl text-[#06989F] font-bold' alt="" >{t('payWithFib')}</a>
                            </div>
                        </div>}
                        <div className={'  w-full relative overflow-clip h-0 align-middle self-center  duration-500'+(this.state.step==3?' h-80 start-0 ':this.state.step<3?' start-[100%] ':'  -start-[100%] ')}>
                        {this.state.step>=3&&<center><CongratsScreen paymentId={this.state.paymentId??'' } ticketPayment={()=>this.ticketPayment()}/></center>}
                        </div>
                        </div>
                        
                    </div>

                    <div className={`w-full lg:w-1/3 my-6 ${this.state.step >= 2?'hidden lg:block': ''}`}>
                        <h2 className='text-2xl mb-6'>{t('details')}</h2>
                        <div className='border shadow-sm flex flex-col items-center px-6'>
                        
                            <div className='w-full flex justify-between items-center my-6 box-content border-b pb-4'>
                                <div className='h-full w-2/6 flex justify-center items-center'>
                                    <img src={this.state.selectTicket['img']} alt="" className='w-full' />
                                </div>
                                <div className='w-5/6 px-1'>
                                    <h3 className='text-[#550200] text-base'>{this.state.selectTicket['name']}</h3>
                                    <ul className='flex flex-row text-gray-600 my-2 text-sm'>
                                        <li className='mx-2'><i className="fa-solid fa-user ml-1 text-[#550200]"></i> {this.state.selectTicket['capacity']}</li>
                                        <li className='mx-2'><i className="fa-solid fa-suitcase-rolling ml-1 text-[#550200]"></i> 10</li>
                                        <li className='mx-2'><i className="fa-solid fa-wifi text-[#550200]"></i></li>
                                    </ul>
                                </div>
                            </div>

                            {/* <div className='h-48 bg-gray-200 w-full mb-6'> */}
                                <FromToImage from={this.props.fromValue} to={this.props.toValue}/>
                            {/* </div> */}

                            <div className='flex flex-col w-full text-sm'>

                                <div className='flex justify-between border-b mb-2 pb-2'>
                                    <div className='text-gray-600 w-1/2'>{t('passengerCount')}:</div>
                                    <div className='text-[#550200] w-1/2 text-center font-bold'>{passengerNumber}</div>
                                </div>

                                <div className='flex justify-between border-b mb-2 pb-2'>
                                    <div className='text-gray-600 w-1/2'>{t('date')}:</div>
                                    <div className='text-[#550200] w-1/2 text-center font-bold'>{date.toLocaleDateString("ar-EG")}</div>
                                </div>

                                {/* <div className='flex justify-between border-b mb-2 pb-2'>
                                    <div className='text-gray-600 w-1/2'>{t('time')}:</div>
                                    <div className='text-[#550200] w-1/2 text-center font-bold'>3:42 PM</div>
                                </div> */}
                            </div>

                            <div className='flex flex-col w-full my-6'>
                                <h3 className='text-[#550100af] mb-2'>{t('couponCode')}</h3>
                                <div className='flex flex-row'>
                                    <input id='couponInput'  className='border border-[#550100af] rounded-br rounded-tr p-1 w-[75%] ' disabled={this.state.couponActiviated}   type="text" placeholder={t('haveACouponCode')} onChange={(event)=>this.setState({couponCode:event.target.value})}/>
                                    
                                    {this.state.couponActiviated?
                                    <button onClick={()=>this.deleteCoupon()} className={`bg-[#550200] text-white rounded-bl rounded-tl p-1 px-2 w-[25%]`}>{t('delete')}</button>:
                                    <button onClick={()=>this.checkCoupon()} className={`bg-[#550200] text-white rounded-bl rounded-tl p-1 px-2 w-[25%]`}>{t('redeem')}</button>}
                                </div>
                                    {this.state.couponActiviated&&
                                    <div className='w-full text-[#0505] text-start'> {t('couponActive')}</div>}
                                    {this.state.couponInvalid&&
                                    <div className='w-full text-[#f00e] text-start'> {t('invalidCoupon')}</div>}
                            </div>
                            <div className='flex flex-col w-full text-sm'>

                            <div className='flex justify-between border-b mb-2 pb-2'>
                                    <div className='text-gray-600 w-1/2'>{t('price')} :</div>
                                    <div className='text-[#550200] w-1/2 text-center font-bold'>{this.state.selectTicket['price']}  IQD</div>
                                </div>

                                {this.state.couponActiviated&&<div className='flex justify-between border-b mb-2 pb-2'>
                                    <div className='text-gray-600 w-1/2'>{t('discount')} :</div>
                                    <div className='text-[#550200] w-1/2 text-center font-bold'>{this.state.couponData.discount +(this.state.couponData.discount_type == 1?'':'%')}  IQD</div>
                                </div>}

                                <div className='flex justify-between border-b mb-2 pb-2'>
                                    <div className='text-gray-600 w-1/2'>{t('totalPrice')}:</div>
                                    <div className='text-[#550200] w-1/2 text-center font-bold'>{this.state.totalPrice} IQD</div>
                                </div>

                                {/* <div className='flex justify-between border-b mb-2 pb-2'>
                                    <div className='text-gray-600 w-1/2'>{t('tax')}:</div>
                                    <div className='text-[#550200] w-1/2 text-center font-bold'>0%</div>
                                </div> */}
                            </div>

                            <div className='flex justify-between w-full m-4 bg-gray-100 p-4 rounded text-lg'>
                                <div className='text-gray-800'>{t('totalAmount')}</div>
                                <div className='text-[#550200] text-center font-bold'>{this.state.totalAmount}  IQD</div>
                            </div>

                        </div>
                    </div>

                    {/* <button className='bg-[#550200] text-white rounded-md px-4 py-2 m-6' onClick={() => this.ticketPayment()}>{t('continuePayment')}</button> */}

                    <div className='w-full'>
                        <div className='max-w-md mx-auto flex justify-between'>
                            {!this.state.paymentDone&&this.state.step>1&&!this.state.stepping?<button onClick={()=>this.previous()} className='bg-[#ffff] text-[#550200] rounded-md px-4 py-2 m-6'>{t('previous')}</button>:<Box/>}
                            {this.state.step<3&&!this.state.stepping?<button onClick={()=>this.next()} className='bg-[#550200] text-white rounded-md px-4 py-2 m-6'>{t('next')}</button>:<Box/>}
                        </div>
                    </div>
                    
                </div>
                : ''
    )
  }
}


export default withTranslation()(Payment)