import React, { Component, useEffect } from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css';
import { withTranslation } from 'react-i18next'

 class Feature extends Component {

  render() {
    Aos.init();
    const{t}=this.props;
    return (
        <div className='flex flex-wrap justify-center gap-y-10  pb-4 pt-20 mx-auto w-full lg:w-2/3 container m-auto'>
            
            <div data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="500" className='flex flex-col justify-center items-center w-1/2 lg:w-1/4 border-l text-center'>
              <img src="/images/icon1.png" className='w-16 lg:w-20 mb-3' alt="" />
              <p className='text-gray-600 font-semibold'>{t('getYourTicketInMinutes')}</p>
            </div>

            <div data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="500" data-aos-delay="100" className='flex flex-col justify-center items-center w-1/2 lg:w-1/4 lg:border-l text-center'>
              <img src="/images/icon2.png" className='w-16 lg:w-20 mb-3' alt="" />
              <p className='text-gray-600 font-semibold'>{t('securePayment')}</p>
            </div>

            <div data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="500" data-aos-delay="200" className='flex flex-col justify-center items-center w-1/2 lg:w-1/4 border-l text-center'>
              <img src="/images/icon3.png" className='w-16 lg:w-20 mb-3' alt="" />
              <p className='text-gray-600 font-semibold'>{t('confidenceAndSecurityWithRamaTravel')}</p>
            </div>

            <div data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="500" data-aos-delay="300" className='flex flex-col justify-center items-center w-1/2 lg:w-1/4  text-center'>
              <img src="/images/icon4.png" className='w-16 lg:w-20 mb-3' alt="" />
              <p className='text-gray-600 font-semibold'>{t("customerService")}</p>
            </div>
        </div>
    )
  }
}

export default withTranslation("translations")(Feature);
