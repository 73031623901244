import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { JitsCopyright } from '../componets/JitsCopyright'

class Footer extends Component {
    

render() {
    const{t}=this.props
    return (
        < div className='bottom-0 w-full' >
      <footer className='bg-[#550200] py-6 flex flex-col justify-center items-center border-t-4 border-[#bf9549] w-full'>
        <div className='my-6'>
            <img src="/images/logo.png" alt="Rama Travel" className='w-28 m-auto' />
        </div>
        <div>
            <ul className='flex gap-x-4'>
                <li className='hover:bg-[#bf9549]  hover:text-[#550200] duration-300 text-[#bf9549] border border-[#bf9549] rounded-full p-2 w-11 h-11 flex justify-center items-center'><a href='https://www.facebook.com/RamaTravel.company?mibextid=LQQJ4d'><i className="fa-brands fa-facebook text-xl"></i></a></li>
                <li className='hover:bg-[#bf9549]  hover:text-[#550200] duration-300 text-[#bf9549] border border-[#bf9549] rounded-full p-2 w-11 h-11 flex justify-center items-center'><a href="https://instagram.com/ramatravel.company?igshid=OGQ5ZDc2ODk2ZA=="><i className="fa-brands fa-instagram text-xl"></i></a></li>
                <li className='hover:bg-[#bf9549]  hover:text-[#550200] duration-300 text-[#bf9549] border border-[#bf9549] rounded-full p-2 w-11 h-11 flex justify-center items-center'><a href="https://t.snapchat.com/nlJI8lER"><i className="fa-brands fa-snapchat text-xl" ></i></a></li>
                <li className='hover:bg-[#bf9549]  hover:text-[#550200] duration-300 text-[#bf9549] border border-[#bf9549] rounded-full p-2 w-11 h-11 flex justify-center items-center'><a href='tel:07509004542'><i className="fa-solid fa-phone text-xl" ></i></a></li>
            </ul>
        </div>
      </footer>
        <div className='bg-[#bf9549] w-full h-8' ><JitsCopyright cr={t("copyright")} sx={{ pt:1 ,color:'white'}} />
        </div>
        
    </div>
    )
  }
}

export default withTranslation()(Footer)