import Aos from "aos";
import React from "react";
import "aos/dist/aos.css";
import { Animate } from "react-simple-animate";
import { LinearProgress } from "@mui/material";

export default function JitsSplashScreen({ onLoadingFinished }) {
  setInterval(function () {
    onLoadingFinished();
  }, 3000);

  Aos.init();
  return (
    <div className="bg-[#5e1f16] w-screen h-screen fixed top-0 left-0 right-0 bottom-0">
      <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center items-center" >
        <Animate
            play={true}
            start={{ opacity: 1, filter: "blur(10px)" }}
            end={{ opacity: 1, filter: "blur(0px)" }}
            duration={2}
          >
            <img
              src="/images/logo.png"
              alt="Rama Travel"
              className="w-[200px] h-[100px]"
            />
          </Animate>
          <Animate
            play={true}
            start={{ opacity: 1, filter: "blur(10px)" }}
            end={{
              opacity: 1,
              filter: "blur(0px)",
              position: "translate(1000px, 10px)",
            }}
            duration={2}
          >
            <LinearProgress
              color="error"
              sx={{ width: "200px", marginTop: "30px" }}
            />
          </Animate>
      </div>
    </div>
  );
}
