import React, { Component } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

class AllDestinationExplorer extends Component {


    state = {
        data: [],
        load: false,
    };

    async componentDidMount(){
        const {data} = await axios.get("https://staffportal.ramatravel.net/api/destinationexplorer");
        this.setState({ data: data }, ()=>{
            this.setState({load: true});
        });
    }


  render() {

    const{t,i18n}=this.props;

    return (
        <div className='py-12 px-5 container m-auto pt-28'>            
            <div className='flex flex-wrap justify-center'>

                {
                    this.state.load? this.state.data.map((item, index) => 
                        {
                            return ( <div key={item['id']} className='bg-slate-50 w-80 flex justify-between items-center py-2 px-3 box-content'>
                                <div>
                                    <h3 className='text-lg text-gray-900'>{item['title']}</h3>
                                    <p className='text-base text-gray-600'>{t('ticket')} {item['vehicleType']}</p>
                                </div>
                                <div className='font-bold text-gray-900'> {item['price']} IQD</div>
                                <div>
                                    <i className={"fa-solid fa-angles-"+(i18n.dir()=='ltr'?'right':'left')+" text-2xl text-[#550200] cursor-pointer"} onClick={() => this.props.handleSetDestinationExplorer(item)}></i>
                                </div>
                            </div>  )
                    }
                                ) 
                    : <div><center><CircularProgress color="error" /></center></div>
                }

            </div>
        </div>
    )
  }
}


export default withTranslation()(AllDestinationExplorer);
