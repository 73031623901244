import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import { useNavigate } from "react-router-dom";

function GoogleCallback() {

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        fetch(`https://staffportal.ramatravel.net/api/login/google/callback${location.search}`, {
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                localStorage.setItem('token', data['token']);
                navigate("/profile");
            });
    }, []);

    return <div></div>
}

export default GoogleCallback;