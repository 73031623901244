import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CssBaseline,
  Divider,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { green, purple } from "@mui/material/colors";

import { useTranslation } from "react-i18next";
import { useAccountStore } from "../../stores/accountStore";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";

export const JitsLogin = (props) => {
  const { login, verify, user } = useAccountStore();

  const navigate = useNavigate();

  // if (token != "") navigate("/profile");
  const [showPassword, setShowPassword] = useState(false);
  const [t, i18n] = useTranslation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [google, setGoogle] = useState("#");
  const [facebook, setFacebook] = useState("#");

  const [loading, setLoading] = useState(true);

  const redirect = () => {
    const selectTicket = sessionStorage.getItem("redirectToPayment");

    if (selectTicket == "true" && useAccountStore.getState().user.token != "") {
      sessionStorage.removeItem("redirectToPayment");
      navigate("/payment");
      return;
    } else if (useAccountStore.getState().user.token != "")
      navigate("/profile");
  };

  if (user.token != "") {
    redirect();
  }
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  async function getProvidersUrl() {
    axios
      .get("https://staffportal.ramatravel.net/api/login/providers/redirect")
      .then((response) => {
        const data = response.data;
        setGoogle(data.google);
        setFacebook(data.facebook);
      })
      .catch((error) => {
        console.error("Error:" + error);
      });
  }

  useEffect(() => {
    const verifyUser = async () => {
      const verified = await verify();
      if (verified) redirect();
      setLoading(false);
    };
    verifyUser();
    getProvidersUrl();
  }, []);

  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#550200",
      },
      secondary: {
        main: green[500],
      },
    },
  });

  async function handleSubmit() {
    setLoading(true);
    await login(username, password);
    setLoading(false);
    redirect();
  }

  return (
    <div dir={useTranslation().i18n.dir()}>
      {loading ? (
        <div className="w-full h-screen flex justify-center items-center ">
          <CircularProgress sx={{ color: "#501212" }} />
        </div>
      ) : (
        <ThemeProvider theme={defaultTheme}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0}
          >
            <Stack
              // height={"50%"}
              direction="row-reverse"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={0}
              className="w-full h-full"
            >
              <div className="hidden lg:block lg:w-1/2">
                <img src="images/login.jpg" className="w-full h-full" />
              </div>

              <div className="lg:w-1/2 m-0 w-full px-20">
                <CssBaseline />
                <Box
                  sx={{
                    marginTop: 12,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/images/redlogo.png"
                    alt="Rama Travel"
                    className="w-32 mb-10"
                  />
                  <Typography component="h1" variant="h4" fontWeight={"bold"}>
                    {t("signinToYourAccount")}
                  </Typography>
                  <Typography component="h2" variant="h6" className="my-8">
                    {t("signinUsingSocialAccounts")}
                  </Typography>

                  <Stack direction={"row-reverse"} alignItems="center">
                    {/* <a href={facebook} className="m-2">
                        <i className="fa-brands fa-facebook text-[#3B5998] text-3xl"></i>
                      </a> */}

                    <a href={google} className="m-2">
                      <i className="fa-brands fa-google text-[#DB4437] text-3xl"></i>
                    </a>
                  </Stack>

                  <Divider sx={{ width: "100%" }}>{t("or")}</Divider>

                  <Box
                    component="form"
                    onSubmit={handleSubmit} //{props.handleLoggedOn}
                    noValidate
                    sx={{ mt: 1 }}
                  >
                    <FormControl sx={{ width: "100%" }} variant="filled">
                      {/* <InputLabel htmlFor="filled-adornment-password">
                        Password
                      </InputLabel> */}
                      <FilledInput
                        placeholder={t("username")}
                        id="filled-adornment-password"
                        onChange={(event) => {
                          setUsername(event.target.value);
                        }}
                        type={"text"}
                        autoFocus
                        sx={{ marginBottom: "10px", borderRadius: "5px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ width: "100%" }} variant="filled">
                      {/* <InputLabel htmlFor="filled-adornment-password">
                        Password
                      </InputLabel> */}
                      <FilledInput
                        placeholder={t("password")}
                        id="filled-adornment-password"
                        onChange={(event) => {
                          setPassword(event.target.value);
                        }}
                        type={showPassword ? "text" : "password"}
                        sx={{ borderRadius: "5px" }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              className={"float-left"}
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    {/* <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                    /> */}
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      onClick={() => handleSubmit()}
                      sx={{ mt: 3, mb: 2 }}
                    >
                      {t("signin")}
                    </Button>
                    <Grid container>
                      <Grid item xs>
                        <Link href="/forgetPassword" variant="body2">
                          {t("forgotPassword")}
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link href="/signup" variant="body2">
                          {t("dontHaveAnAccountSignup")}
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box height={30} />
                {/* <Copyright cr={t("copyright")} sx={{ mt: 8, mb: 4 }} /> */}
              </div>
            </Stack>
          </Stack>
        </ThemeProvider>
      )}
    </div>
  );
};
