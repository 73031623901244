import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
  ThemeProvider,
  createTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { green } from '@mui/material/colors';
import { useAccountStore } from '../../stores/accountStore';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const states = [
  {
    value: 'duhok',
    label: 'Duhok'
  },
  {
    value: 'erbil',
    label: 'Erbil'
  },
  {
    value: 'sulaymaniyah',
    label: 'Sulaymaniyah'
  }
];

export const AccountProfileDetails = () => {
  const {t}=useTranslation();
  const navigate=useNavigate();
  
  const account=useAccountStore(state=>state.user);
  const update=useAccountStore(state=>state.update);
  const logout=useAccountStore(state=>state.logout);

  const [showPasswordChange,setShowPasswordChange]=useState(false);
  const [values, setValues] = useState({
    // avatar:account.avatar,
    username: account.username,
    email: account.email,
    phone: account.mobile,
    dob: format(new Date(account.dob), 'yyyy-MM-dd'),
    gender:account.gender,
  });


  const handleChange = (event) => {
      const { name, value } = event.target;
      setValues((prevState) => ({ ...prevState, [name]: value }));      
  }

  const handleSubmit = async () => {
    const status = await update(values.username, values.email, values.phone, values.dob, values.gender);

    if(status) {
      toast.success(t("ProfileUpdatedSuccess"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }else {
      toast.error(t("ProfileUpdatedFailed"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
    

    // navigate('/');
    // event.preventDefault();
  }

  const handleLogout=()=>{
    logout();
    navigate('/');
  }

  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#550200",
      },
      secondary: {
        main: green[500],
      },
    },
  });
  return (
    
    <ThemeProvider theme={defaultTheme}>
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
    >


      <Card>
        <CardHeader
          subheader={t("theInformationCanBeEdited")}
          title={t('profile')}
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  helperText={t("pleaseSpecifyTheUsername")}
                  label={t("username")}
                  name="username"
                  onChange={handleChange}
                  required
                  value={values.username}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label={t("email")}
                  name="email"
                  onChange={handleChange}
                  required
                  value={values.email}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label={t("phoneNo")}
                  name="phone"
                  onChange={handleChange}
                  type="text"
                  value={values.phone}
                />
              </Grid><Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label={t("dateOfBirth")}
                  name="dob"
                  onChange={handleChange}
                  type="date"
                  value={ values.dob }
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
               <TextField
                  fullWidth
                  label={t("selectGender")}
                  name="gender"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.gender}
                >
                  
                    <option
                    key="male"
                    value={1}
                  >
                     {t('male')}
                  </option> 
                  <option
                      key="female"
                      value={2}
                    >
                      {t('female')}
                    </option>
                  
                </TextField>
              </Grid>

            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'space-between' }}>
          <Button variant="text" onClick={()=>handleLogout()}>
           {t("logout")}
          </Button>
          <Button variant="contained" onClick={()=>handleSubmit()}>
           {t("saveDetails")}
          </Button>
        </CardActions>
      </Card>
    </form>
    <ToastContainer />
    </ThemeProvider>
  );
};
