import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { JitsDrawer } from '../componets/JitsDrawer';
import { Tooltip, styled, tooltipClasses } from '@mui/material';
import { JitsLanguageDropDown } from '../componets/JitsLanguageDropDown';
import { Person } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useAccountStore } from '../../stores/accountStore';

class Navigation extends Component {
  render() {

    const{t,i18n}=this.props;
    const user =  useAccountStore.getState().user;

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#f5f5f9',
          color: '#bf9549',
          maxWidth: 400,
          padding:10,
          margin:0,
          fontSize: theme.typography.pxToRem(12),
          border: '2px solid #bf9549',
        },
      }));
      
    return (

        <div className='w-full' dir={i18n.dir()}>

        <div className="bg-header-wave w-full absolute z-0 rotate-180 lg:bg-[#6a201e]">
            <svg className='h-full' width="100%" height="100%" version="1.1" x="0px" y="0px" viewBox="0 0 1698 135" preserveAspectRatio="none" xmlns="https://www.w3.org/2000/svg" >
                <path opacity="0.2" fill="#5e1f16" d="M1999.5,22.2c-346-0.6-524.6-4.7-878.8,4.4c-286.6,7.4-442.3,54-608.1,51.2C307.3,74.3,202.5,5-0.5,28.1v100.4l2000-0.5V22.2z"></path>
                <path opacity="0.2" fill="#5e1f16" d="M-0.3,46.1C251,15.3,440.9,84.7,499.6,98.4c54.7,12.8,122.5,12,186.7-5.3c154.2-41.6,315.5-70.9,475.2-67.5s324.6,22.4,484.3,19.7c133-2.3,302.8,1.7,352.8,3.7c0,21.3,0,80,0,80H-0.5L-0.3,46.1z"></path>
                <path opacity="0.4" fill="#521411" d="M2000,41.2c-139.8-12.7-219.9-10.8-360.2-11.2c-285.5-0.8-487.5,18-736.2,51.1C647,115.4,546.7,116.4,199.2,53.6C140.3,43,59.5,45.6-0.5,52.3V130h2000L2000,41.2z"></path>
                <path fill="#501212" d="M1634.6,50.1c-293.8,11.9-366.9,24.9-569,50c-110.2,13.7-221.2,21.5-332.3,19.6c-187-3.3-344.5-29.7-560.9-69.8c-122.2-22.6-172.8-4-172.8-4V230h1998V46C1997.5,46,1831,38.1,1634.6,50.1z"></path>
            </svg>
        </div>

        <nav className='flex justify-around items-center p-4 text-gray-200 relative z-10 h-20 xl:h-28'>
        <JitsDrawer showOtherPage = {this.props.showOtherPage} />
            {/* <button className='xl:hidden absolute right-6'>
                <i className="fa-solid fa-bars text-xl"></i>
            </button> */}
            <div className='hidden xl:flex w-1/3 flex-wrap items-center'>

                <div className={`mx-3`}>
                    <Link to={user.token!=""?"/profile":"/login"}>
                        {
                            user.token!=""?
                                user.avatar=="https://staffportal.ramatravel.net/storage/appusers"? 
                                    <Person className='w-10 h-10 rounded-full shadow-slate-50 shadow-sm'/>:
                                    <img className='w-10 h-10 rounded-full shadow-slate-50 shadow-sm' src={`${user.avatar}`} alt="" />:
                                <span className='border border-[#6a201e] rounded-md py-2 px-3 text-sm'>{t('Login')}</span>
                        }
                    </Link>
                </div>
                {/* <div className='border border-[#6a201e] rounded-md flex justify-center items-center px-1 h-8'> */}
                    {/* <i className="fa-solid fa-earth-asia"></i> */}
                    {/* <select name="" id="" className='head-select-lang bg-transparent px-2' onChange={ (e) =>  this.changeLanguageHandler(e.target.value) } >
                        <option value="en">English</option>
                        <option value="ar">عربي</option>
                        <option value="ckb">کوردی</option>
                    </select> */}
                    <JitsLanguageDropDown setClass='border border-[#6a201e] rounded-md flex justify-center items-center px-1 h-8' />

                {/* </div> */}

            </div>

            <div className='w-full xl:w-1/3'>
                <Link to={"/"} onClick={  this.props.showMain }>
                    <img src={`/images/logo.png`} alt="Rama Travel" className='hidden lg:block w-28 xl:w-28 mx-auto mt-10' />
                    <img src="/images/redlogo.png" alt="Rama Travel" className='block lg:hidden w-28 xl:w-28 mx-auto mt-10' />
                </Link>
            </div>

            <div className='block xl:hidden absolute top-7 left-3'>
                <Link to={user.token!=""?"/profile":"/login"}>
                    {
                        user.token!=""?
                            user.avatar=="https://staffportal.ramatravel.net/storage/appusers"? 
                                 <Person className='w-10 h-10 rounded-full shadow-slate-50 shadow-sm'/>:
                                <img className='w-10 h-10 rounded-full shadow-slate-50 shadow-sm' src={`${user.avatar}`} alt="" />:
                            <span className='border border-[#6a201e] rounded-md p-1 text-sm'>{t('Login')}</span>
                    }
                </Link>
            </div>

            <div className='hidden xl:block w-1/3'>
                <ul className='flex flex-row justify-end items-center gap-6'>
                    
                    <li>
                        <h3 className='hover:animate-bounce text-white text-xl font-medium'>
                            <Link to={"/"} onClick={  this.props.showMain }> 
                                <span className=' text-[#bf9549]'>{t('home')}</span>
                            </Link>
                        </h3>
                    </li>

                    <li>
                        {/* <span className="item-nav-transition"></span> */}
                        <h3 className='hover:animate-bounce text-white text-xl font-medium'>
                            <Link to={"/groups"} onClick={  this.props.showOtherPage }> 
                                <span className=' text-[#bf9549]'>{t('view')}</span> {t('groups')}
                            </Link>
                        </h3>
                    </li>
                    <li>
                        {/* <span className="item-nav-transition"></span> */}
                        <h3 className='hover:animate-bounce text-white text-xl'>
                            <Link to={'/guides'} onClick={  this.props.showOtherPage }> 
                                <span className='text-[#bf9549]'>{t('read')}</span> {t('guides')}
                            </Link>
                        </h3>
                    </li>
                    <li>
                        {/* <span className="item-nav-transition"></span> */}
                        <h3 className='hover:animate-bounce text-white text-xl'>
                            <Link to={'/about'} onClick={  this.props.showOtherPage }>
                                <span className='text-[#bf9549]'>{t('about')}</span> {t('rama')}
                            </Link>
                        </h3>
                    </li>
                    
                </ul>
            </div>
        </nav>

        </div>
        

    )
  }
}

export default withTranslation()(Navigation)