import React, { Component } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import {ComingSoon} from '../componets/ComingSoon.tsx'

class TravelExplorer extends Component {

  state = {
      data: [],
      load: false,
      type: 'Foreign'
  };

  async componentDidMount(){
      const {data} = await axios.get("https://staffportal.ramatravel.net/api/travelExplorer");
      this.setState({ data: data }, ()=>{
          this.setState({load: true});
      });
  }

  setType = (type) => {
    this.setState({type: type});
  }


  render() {
    const{t,i18n}=this.props;
    return (
        <div className='travel-explorer mt-5 bg-gray-100 py-4'>

        <div className='container m-auto'>
        <h2 className='text-2xl text-[#550200] font-medium pr-16'>{t('discoverInternalAndNationalDestinationsWithRama')}</h2>
        <div className='travel-explorer-head flex my-8 pr-16 '>
          <button className={`${this.state.type == 'Foreign'? 'active': ''}`} onClick={() => this.setState({type: 'Foreign'})}>{t('nationalTrips')}</button>
          <button className={`${this.state.type == 'Domestic'? 'active': ''}`} onClick={() => this.setState({type: 'Domestic'})}>{t('internalTrips')}</button>
        </div>

        <div className='flex flex-nowrap gap-x-5 overflow-x-scroll h-96 px-6 relative'>
              {
                    this.state.type == 'Domestic'?<ComingSoon/>:this.state.load ? 
                      this.state.data.map(item => { 
                          return (
                                item['type'] == this.state.type && 
                                  <div key={item['id']} className='border-2 border-[#550200] shadow-md shadow-[#550200] rounded-2xl min-w-[250px] w-[250px] h-[95%] bg-gray-500 relative box-content' style={{'backgroundImage': `url("${item['background']}")`}}>
                                    <span className='text-white bg-[#550200] py-1 px-4 rounded-2xl absolute top-2 right-2 text-xs'>{t('startsFrom')} {item['startPrice']} IQD</span>
                                    <h3 className='text-white text-lg absolute top-9 right-2 left-2 font-bold'>
                                      {i18n.language=='en'&& item['title']}
                                      {i18n.language=='ar'&& item['title_ar']}
                                      {i18n.language=='ckb'&& item['title_ku']} 
                                    </h3>
                        
                                    <div className='h-16 w-full bg-red-950 bg-opacity-60 absolute bottom-0 flex justify-between items-center rounded-br-2xl rounded-bl-2xl' >
                                      {/* <div className='w-1/2'>
                                        <img src={item['vehicleImg']} className='w-full mb-3' alt="" />
                                      </div> */}
                                      <div className='w-full text-white text-base font-medium px-2'>{t('onlyFor')} {item['price']} {t('dinar')}</div>
                                    </div>
                                  </div> 
                            )
                        }) 
                    : <div><center><CircularProgress color="error" /></center></div>
                }
        </div>
        </div>
        

      </div>
    )
  }
}

export default withTranslation()(TravelExplorer)
