import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#550200",
    },
    secondary: {
      main: green[500],
    },
  },
});

export default function JitsPackageDetails(props) {

  const location = useLocation();

  const p = location.state.p;
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={defaultTheme}>
      <br />
      <div className="text-4xl py-4 px-8 text-center">{p.name}</div>
      <hr />

      <center>
        <iframe
          className="py-1 lg:mx-10 mb-6 text-center w-full lg:w-2/3 h-[1150px]"
          src={p.pdf}
        ></iframe>
      </center>
      <center className="rounded-md w-full text-xl pb-4 text-[#bf9549]">
        <Link target="_blank" download to={p.pdf}>
          <Button variant="contained" sx={{ color: "#bf9549" }}>
            {t("downloadPDF")}
          </Button>
        </Link>
      </center>
    </ThemeProvider>
  );
}
