import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@mui/material';
import { useAccountStore } from '../../stores/accountStore';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


export const AccountProfile = (props) =>{
  const user=useAccountStore((state)=>state.user);
  const updateAvatar =useAccountStore((state)=>state.updateAvatar);
  const {t,i18n}=useTranslation();


  const [file, setFile] = useState([]);

  const navigate=useNavigate();

  const handleFile = event => {
        setFile( URL.createObjectURL(event.target.files[0]) );
        const formData = new FormData();
        formData.append("avatar", event.target.files[0]);

        const token = localStorage.getItem('token');

        fetch('https://staffportal.ramatravel.net/api/editUserAvatar', {
            method: 'POST',
            headers: new Headers({
                "Authorization": `Bearer ${token}`,
            }), 
            body: formData,
            dataType: "jsonp"
        }).then((response) => response.json())
        .then((data) => {
          updateAvatar(data.avatar);

          toast.success(t("ProfileUpdatedSuccess"), {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });

        })
        .catch((error) => {
            
          toast.error(t("ProfileUpdatedFailed"), {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });

        });
    };

 return <Card >
    <CardContent>
     
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Avatar
          src={user.avatar}
          sx={{
            height: 80,
            mb: 2,
            width: 80
          }}
        />
        <Typography
          gutterBottom
          variant="h5"
        >
          {user.name}
        </Typography>
      </Box>
    </CardContent>
    <Divider />
    <CardActions>
      <input 
            id='avatarInput'
            type="file" 
            onChange={handleFile}
            className='w-[0.1px] h-[0.1px] opacity-0 overflow-hidden absolute z-[-1] '
            />
      <label htmlFor="avatarInput" className='text-lg bg-[#6a201e]  text-white w-full text-center p-2 rounded-sm cursor-pointer'>{t('chooseAFile')}</label>
    </CardActions>
   {(props.showTickets||props.showPasswordChanger)&&<> <Divider />
    <CardActions>

    <Button
        fullWidth
        variant="text"
        sx={{color:'#6a201e'}}
        onClick={()=>{props.handleSetShowTickets(false);
          props.setShowPasswordChanger(false);}}
      >
        {t('account')}
      </Button>
    
    </CardActions></>
}
    

   {!props.showTickets&&<> <Divider />
    <CardActions><Button
        fullWidth
        variant="text"
        sx={{color:'#6a201e'}}
        onClick={()=>{props.handleSetShowTickets(true);props.setShowPasswordChanger(false)}}
      >
        {t('tickets')}
      </Button>
      </CardActions></>}
      {
        !props.showPasswordChanger&&<>
         <Divider />
         <CardActions>
      <Button
        fullWidth
        variant="text"
        sx={{color:'#6a201e'}}
        onClick={()=>{props.setShowPasswordChanger(true);props.handleSetShowTickets(false)}}
        
      >
        {t('password')}
      </Button>
    </CardActions></>}
    <ToastContainer />
  </Card>
;}
