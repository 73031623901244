import React, { Component } from 'react'
import {  withTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import {ComingSoon} from '../componets/ComingSoon.tsx'

class Ticket extends Component {

    state = {
        tickets: [],
        loading: true,
    }

    componentDidMount() {
        // JSON.parse(sessionStorage.getItem('tickets'))
        this.setState({tickets: this.props.tickets }, () => {
            setTimeout(()=> {
                this.setState({ loading: false });
            }, 500);
        });
    }
    
    render() {

    const {t}=this.props

    const { passengerNumber } = this.props;

    // if(this.props.vehicleType == 'car')
    //     return <div className='w-full flex justify-center items-center bg-gray-100 pb-6 pt-20 min-h-[45vh] text-2xl'><ComingSoon/></div>
    if(this.state.length==0)
        return <div className='w-full flex justify-center items-center bg-gray-100 pb-6 pt-20 min-h-[35vh]'><h4>No tickets found</h4></div>
    if(this.state.loading)
        return <div className="w-full flex justify-center items-center bg-gray-100 pb-6 pt-20 min-h-[35vh]"><CircularProgress sx={{color:'#501212'}}/></div>
    return (
      <div className='bg-gray-100 pb-6 pt-20 min-h-[50vh]'>
        <p className='my-6 mr-12 text-gray-500 text-center'>{t('searchResults')}</p>

        <div className='container ticket-items mx-auto p-4 flex flex-wrap justify-center gap-4'>

            {
                (this.props.tickets && this.props.tickets.length > 0) ? this.props.tickets.map(item => 
                    <div key={item['id']} className='w-full lg:w-2/3 flex justify-between items-center bg-white rounded-md shadow-md h-52 lg:h-32 mb-6 box-content'>
                        <div className='bg-gray-100 h-full w-1/3 lg:w-2/6 flex justify-center items-center px-2'>
                            <img src={item['img']} alt="" className='w-32' />
                        </div>
                        <div className='w-2/3 lg:w-4/6 flex flex-col lg:flex-row px-4 lg:px-0'>
                            <div className='w-full lg:w-4/6 lg:px-3 mb-2 lg:mb-0'>

                                <h3 className='text-[#550200] text-lg'>{item['name']}</h3>
                                
                                <ul className='flex flex-row text-gray-600 my-2'>
                                    <li className='mx-2'><i className="fa-solid fa-user ml-1 text-[#550200]"></i> {item['capacity']}</li>
                                    <li className='mx-2'><i className="fa-solid fa-suitcase-rolling ml-1 text-[#550200]"></i> 10</li>
                                    <li className='mx-2'><i className="fa-solid fa-wifi text-[#550200]"></i></li>
                                </ul>

                                <div className='text-xs text-gray-500 flex justify-between flex-col lg:flex-row items-start'>
                                    <div>
                                        {/* {t('departureTime')}: <span className='text-[#550200] mx-1'>7:00 PM</span> */}
                                    </div>
                                    <div className='flex flex-row lg:flex-col gap-2 lg:gap-1 items-center'>
                                        <span>{ ( this.props.vehicleType == 'car' ? t('cost') : t('costPerPerson') ) }</span>
                                        <span className='text-[#550200]'>{item['price']} IQD</span>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full lg:w-2/6 flex justify-between items-center flex-col border-t lg:border-t-0 lg:border-r'>
                                <div className='flex flex-row lg:flex-col gap-2 lg:gap-1 lg:items-center mt-2 lg:mt-0 lg:p-2'>
                                    <strong className='text-lg'>{ (this.props.vehicleType=='bus'? passengerNumber:1) * item['price']}  IQD</strong>
                                    <span className='text-gray-600 text-sm'>{t('total')}</span>
                                </div>
                                <button className='bg-[#550200] text-white rounded-md px-2 mt-2' onClick={() => this.props.selectTicket(item)}>{t('clickToReserve')}</button>
                            </div>
                        </div>
                    </div> )
            : <p>{t('noTicketsAvailable')}</p>
            }

        </div>
      </div>
    )
  }
}


export default withTranslation()(Ticket)