import React, { Component } from 'react'
import { Navigate } from "react-router-dom"

export default class NotFound extends Component {

    state = {
        redirect: false,
    }

    componentDidMount(){
        setTimeout(() => {
            this.setState({redirect: true});
        }, 500);
    }
  render() {
    return (
    
        this.state.redirect ? <Navigate to='/'/>
        : <div className='bg-[#6a201e] fixed top-0 bottom-0 left-0 right-0 w-full h-full flex justify-center items-center z-40'>
            <h1 className='text-center text-3xl text-white'>Page Not Found</h1>
        </div>
    )
  }
}
