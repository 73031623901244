import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import { t } from "i18next";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAccountStore } from "../../stores/accountStore";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import React from "react";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#550200",
    },
    secondary: {
      main: green[500],
    },
  },
});

export default function JitsSignup(props) {
  const navigate = useNavigate();
  const signup = useAccountStore().signup;

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setmobile] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [gender, setGender] = useState(1);
  const [dob, setDob] = useState("");

  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      username === "" || password === "" || cpassword === "" ||
      email === "" || mobile === "" || dob === "" ||
      password !== cpassword
    )
      return;
    const data = {
        username: username,
        password: password,
        email: email,
        mobile: mobile,
        gender: gender,
        dob: dob,
      };
    
    setSubmitting(true);
    const e = await signup(data);
    if (e === "") navigate("/");
    else setError(e);
    setSubmitting(false);

  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <div dir={useTranslation().i18n.dir()}>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
          <Dialog
            open={error.length !== 0}
            onClose={() => {
              setError("");
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{
              direction: useTranslation().i18n.dir(),
            }}
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="w-full">
                  <div className="w-full flex flex-row justify-between mb-4">
                    <i className="fa-solid fa-triangle-exclamation text-xl"></i>
                    <i
                      className="fa-solid fa-xmark cursor-pointer text-xl"
                      onClick={() => {
                        setError("");
                      }}
                    ></i>
                  </div>
                  <div className="w-max">{t(error)}</div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 6,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                {t("signup")}
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="username"
                      name="username"
                      required
                      fullWidth
                      id="username"
                      label={t("username")}
                      autoFocus
                      onChange={(event) => setUsername(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      type="email"
                      label={t("email")}
                      name="email"
                      autoComplete="email"
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="mobile"
                      label={t("phoneNo")}
                      type="mobile"
                      id="mobile"
                      autoComplete="tel"
                      onChange={(event) => setmobile(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label={t("password")}
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="cpassword"
                      label={t("confirmPassword")}
                      type="password"
                      id="cpassword"
                      autoComplete="new-password"
                      onChange={(event) => setCPassword(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      required
                      fullWidth
                      name="dob"
                      label={t("dateOfBirth")}
                      type="date"
                      id="dob"
                      autoComplete="bday"
                      onChange={(event) => setDob(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label={t("selectGender")}
                      autoComplete="sex"
                      name="gender"
                      onChange={(event) =>
                        setGender(event.target.value === "1" ? 1 : 0)
                      }
                      required
                      select
                      SelectProps={{ native: true }}
                      value={gender}
                    >
                      <option key="male" value={1}>
                        {t("male")}
                      </option>
                      <option key="female" value={0}>
                        {t("female")}
                      </option>
                    </TextField>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {submitting ? <CircularProgress sx={{color:"white"}}/> : t("signup")}
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link href="/login" variant="body2">
                      {t("alreadyHaveAnAccountSignIn")}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </Stack>
      </div>
    </ThemeProvider>
  );
}
