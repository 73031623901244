import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import { t } from "i18next";
import { CircularProgress, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAccountStore } from "../../stores/accountStore";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#550200",
    },
    secondary: {
      main: green[500],
    },
  },
});

export default function JitsPasswordChange(props) {
  const navigate = useNavigate();
  const changePassword = useAccountStore().changePassword;
  const logout = useAccountStore().logout;
  const user = useAccountStore((state)=>state.user);

  const [opassword, setOPassword] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");



  const [wip,setWip]=useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setWip(true);
    var error = null;
    if(user.havePassword && opassword=="")
      error = t("oldPasswordIsEmpty");
    else if(password=="")
      error = t("newPasswordIsEmpty");
    else if(cpassword=="")
      error = t("pleaseRetypeTheNewPassword");
    else if(cpassword!=password)
      error = t("newPasswordsDontMatch");

    if(error)
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });    

    if (
      (user.havePassword && opassword=="") ||
      password == "" ||
      cpassword == "" ||
      password != cpassword
    )
      {
        setWip(false);
        return;
      }

    const data = {
      password: password,
      opassword: opassword,
    };

    const changeStatus = await changePassword(data);
    if(changeStatus)
    {  
      toast.success(t('passwordChangedSuccessfullyPleaseRelogin'), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });   

     setTimeout(() => {
      logout();
      navigate('/login');
     }, 1000)
    }
    else
      toast.error(t('passwordChangingFailed'), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });    

      setOPassword("");
      setPassword("");
      setCPassword("");

  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <div dir={useTranslation().i18n.dir()}>
        {/* <Navigation /> */}
        <Stack
        className=" h-96"
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
          {wip?<CircularProgress/>:<Container component="main" maxWidth="xs" className=" min-h-max">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 6,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                {t("changePassword")}
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  
                  {
                    user.havePassword? <Grid item xs={12}>
                                          <TextField
                                            required
                                            fullWidth
                                            name="opassword"
                                            label={t("currentPassword")}
                                            type="password"
                                            id="opassword"
                                            autoComplete="password"
                                            onChange={(event) => setOPassword(event.target.value)}
                                          />
                                        </Grid> : ''
                  }
                  

                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label={t("newPassword")}
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      color={`${(password.length > 0 && password.length < 8)? 'warning' : (password.length >= 8)? 'success': ''}`}
                      onChange={(event) => { setPassword(event.target.value) }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="cpassword"
                      label={t("confirmNewPassword")}
                      type="password"
                      id="cpassword"
                      autoComplete="new-password"
                      color={`${(cpassword.length > 0 && cpassword !== password )? 'warning' : (cpassword == password)? 'success': ''}`}
                      onChange={(event) => setCPassword(event.target.value)}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {t("change")}
                </Button>
              </Box>
            </Box>
          </Container>}
        </Stack>
      </div>
      <ToastContainer />
    </ThemeProvider>
  );
}
