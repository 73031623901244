import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).
    use(LanguageDetector)
.init({
  fallbackLng: 'ckb',
  // lng: 'ar',
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    ar: {
      translations: require('./locales/ar/translations.json')
    },
    ckb: {
      translations: require('./locales/ckb/translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'ar','ckb	'];

export default i18n;